<template>
  <div>
    <b-row>
      <b-col
        md="12"
        lg="5"
      >
        <b-card title="Preencha todos os campos para cadastrar seu grupo 🚀">
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent="createGroup">
              <b-row>
                <!-- NOME DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Nome do Grupo"
                    label-for="name-grupo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nome do Grupo"
                      rules="required"
                    >
                      <b-form-input
                        id="name-group"
                        v-model="nomegrupo"
                        :state="errors.length > 0 ? false:null"
                        placeholder="NOME DO GRUPO"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- TIPO DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Tipo do Grupo"
                    label-for="tipogrupo"
                  >
                    <validation-provider
                      name="Tipo do Grupo"
                      rules="required"
                    >
                      <b-form-select
                        id="tipogrupo"
                        v-model="tipogrupo"
                        :options="options"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- LINK DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Link do Grupo"
                    label-for="link-grupo"
                  >
                    <validation-provider
                      name="Link do Grupo"
                      rules="required"
                    >
                      <b-form-input
                        id="link-group"
                        v-model="linkgrupo"
                        placeholder="LINK DO GRUPO"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- CODIGO DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Código do Grupo"
                    label-for="link-group"
                  >
                    <validation-provider
                      name="Código do Grupo"
                      rules="required"
                    >
                      <b-form-input
                        id="link-group"
                        v-model="codigogrupo"
                        placeholder="CODIGO DO GRUPO"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- CLIENTE -->
                <b-col cols="12">
                  <b-form-group
                    label="Cliente"
                    label-for="link-group"
                  >
                    <v-select
                      v-model="cliente"
                      :options="optionsCliente"
                      label="name"
                      class="select-size-lg"
                    />
                  </b-form-group>
                </b-col>

                <!-- STATUS -->
                <b-col cols="12">
                  <b-form-group
                    label="Status"
                    label-for="link-group"
                  >
                    <v-select
                      v-model="status"
                      :options="optionsStatus"
                      label="title"
                      class="select-size-lg"
                    />
                  </b-form-group>
                </b-col>

                <!-- login button -->
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="createGroup"
                  >
                    Cadastrar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BForm, BButton, BRow, BCol, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import funcs from '@/services/func.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const user = JSON.parse(localStorage.getItem('admin'))

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormSelect,
    BFormGroup,
    BFormInput,
    vSelect,
    BForm,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      nomegrupo: '',
      tipogrupo: null,
      linkgrupo: '',
      codigogrupo: '',
      cliente: '',

      status: '',
      options: [
        { value: 'vendas', text: 'VENDAS' },
        { value: 'amizades', text: 'AMIZADES' },
        { value: 'outros', text: 'OUTROS' },
      ],
      optionsCliente: [],
      optionsStatus: [
        { key: 'pendente', title: 'Pendente' },
      ],
    }
  },
  mounted() {
    this.listAllClients()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
    },

    listAllClients() {
      funcs.listAllClients().then(success => {
        console.log(success)
        if (success.data) {
          this.optionsCliente = success.data
        }
      })
    },
    createGroup() {
      const body = {
        name: this.nomegrupo, type: this.tipogrupo, link: this.linkgrupo, code: this.codigogrupo, cliente: this.cliente, status: this.status,
      }
      funcs.creategroupadmin(user.id, body).then(success => {
        console.log(success)
        if (success.data.error === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              icon: 'CheckIcon',
              text: success.data.message,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        } else if (success.data.code === 'ER_DUP_ENTRY') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'XIcon',
              text: 'Já existe cadastro desse grupo',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'XIcon',
              text: 'Verifique os campos digitados!',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
